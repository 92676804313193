
import { computed, defineComponent } from 'vue';
import SelecionarTipoRastreabilidade from '@/components/Estoques/Rastreabilidade/SelecionarTipoRastreabilidade.vue';
import SelecionarTipoLancamentoRastreabilidade from '@/components/Estoques/Rastreabilidade/SelecionarTipoLancamentoRastreabilidade.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Estoques/Rastreabilidade/ETipoRastreabilidade';

export default defineComponent({
  name: 'ProdutoEstoque',
  props: {
    tipoRastreabilidade: {
      type: Number,
      required: true,
    },
    tipoLancamentoRastreabilidade: {
      type: Number,
      required: true,
    },
    pontoPedido: {
      type: Number,
      required: true,
    },
    tempoReposicao: {
      type: Number,
      required: true,
    },
    bloquearNovasCompras: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarTipoRastreabilidade,
    SelecionarTipoLancamentoRastreabilidade,
    SelecionarSimNao,
  },
  emits: ['update:tipoRastreabilidade', 'update:tipoLancamentoRastreabilidade',
    'update:pontoPedido', 'update:tempoReposicao', 'update:bloquearNovasCompras'],
  setup(props, { emit }) {
    const computedTipoRastreabilidade = computed({
      get: () => props.tipoRastreabilidade,
      set: (valor: number) => {
        emit('update:tipoRastreabilidade', valor);
      },
    });

    const computedTipoLancamentoRastreabilidade = computed({
      get: () => props.tipoLancamentoRastreabilidade,
      set: (valor: number) => {
        emit('update:tipoLancamentoRastreabilidade', valor);
      },
    });

    const computedPontoPedido = computed({
      get: () => props.pontoPedido,
      set: (valor: number) => {
        emit('update:pontoPedido', valor);
      },
    });

    const computedTempoReposicao = computed({
      get: () => props.tempoReposicao,
      set: (valor: number) => {
        emit('update:tempoReposicao', valor);
      },
    });

    const computedBloquearNovasCompras = computed({
      get: () => props.bloquearNovasCompras,
      set: (valor: boolean) => {
        emit('update:bloquearNovasCompras', valor);
      },
    });

    return {
      props,
      ETipoRastreabilidade,
      computedTipoRastreabilidade,
      computedTipoLancamentoRastreabilidade,
      computedPontoPedido,
      computedTempoReposicao,
      computedBloquearNovasCompras,
    };
  },
});
