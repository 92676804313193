
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import { INotaFiscalItem, INotaFiscalItemEstoque } from '@/models/Entidades/Fiscal/INotaFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import SelecionarCentroCusto from '@/components/Cadastros/PlanosContas/SelecionarCentroCusto.vue';
import Icone from '@/core/components/Icone.vue';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import SelecionarEstoqueMovimentoMobile from '@/components/Cadastros/Estoques/SelecionarEstoqueMovimentoMobile.vue';

export default defineComponent({
  name: 'NotaFiscalItemEstoques',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    editarQuantidades: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as () => INotaFiscalItem,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    MensagemSemDados,
    SelecionarEstoqueMovimentoMobile,
    SelecionarEmpresa,
    SelecionarEstoque,
    SelecionarCentroCusto,
  },
  emits: ['update:item', 'totalAlterado'],
  setup(props, { emit }) {
    const servicoEstoque = new ServicoEstoque();
    servicoEstoque.requisicaoSistema();

    const state = reactive({
      total: 0,
      estoquesUtilizados: [] as number[],
      itemEstoqueUtilizado: 0,
      apresentarEstoques: false,
    });

    const computedItem = computed({
      get: () => props.item,
      set: (val: INotaFiscalItem) => {
        emit('update:item', val);
      },
    });

    async function obterCodigoEstoque(index: number) {
      if (computedItem.value.estoques[index].codigoEstoqueItem !== undefined && computedItem.value.estoques[index].codigoEstoqueItem !== 0) {
        const item = await servicoEstoque.obterItem(computedItem.value.estoques[index].codigoEstoqueItem, props.empresa);
        computedItem.value.estoques[index].codigoEstoque = item.codigoEstoque;
      }
    }

    function atualizarTotal() {
      state.total = 0;
      computedItem.value.estoques.forEach((e) => {
        state.total += e.quantidade;
      });
      emit('totalAlterado', state.total);
    }

    function atualizarEstoquesUtilizados() {
      state.estoquesUtilizados = [] as number[];
      computedItem.value.estoques.forEach((e) => {
        console.log('Item Estoque: ', e.codigoEstoqueItem, ' Codigo EStoque: ', e.codigoEstoque);
        state.itemEstoqueUtilizado = e.codigoEstoqueItem;
        state.estoquesUtilizados.push(e.codigoEstoque);
      });
    }

    function adicionarEstoque() {
      const itemEstoque = {} as INotaFiscalItemEstoque;
      itemEstoque.codigoEmpresa = props.empresa;
      itemEstoque.codigoEstoqueItem = state.itemEstoqueUtilizado;
      computedItem.value.estoques.push(itemEstoque);
    }

    function removerEstoque(index: number) {
      computedItem.value.estoques.splice(index, 1);
      atualizarEstoquesUtilizados();
    }

    function removerTodos() {
      computedItem.value.estoques = [] as INotaFiscalItemEstoque[];
      state.total = 0;
      atualizarEstoquesUtilizados();
    }

    watch(async () => computedItem, async () => {
      atualizarEstoquesUtilizados();
    });

    return {
      props,
      state,
      computedItem,
      atualizarTotal,
      atualizarEstoquesUtilizados,
      obterCodigoEstoque,
      adicionarEstoque,
      removerEstoque,
      removerTodos,
      UtilitarioGeral,
      storeSistema,
    };
  },
});
