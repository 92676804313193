
import {
  computed, defineComponent, reactive, ref, watch,
} from 'vue';

import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { INotaFiscalItemEstoque } from '@/models/Entidades/Fiscal/INotaFiscal';
import { IDTOProdutoEstoqueMovimento } from '@/models/DTO/Cadastros/Estoques/IDTOProdutoEstoqueMovimento';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'SelecionarEstoqueMovimentoMobile',
  props: {
    codigoProdutoDefinicao: {
      type: Number,
      default: 0,
    },
    codigoUnidadePrincipal: {
      type: Number,
      default: 0,
    },
    fatorConversaoUnidadePrincipal: {
      type: Number,
      default: 0,
    },
    codigoUnidadeSelecionada: {
      type: Number,
      default: 0,
    },
    fatorConversaoUnidadeSelecionada: {
      type: Number,
      default: 0,
    },
    empresas: {
      type: Array as () => number[],
      default: [] as [],
    },
    quantidade: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    itensEstoque: {
      type: Array as () => INotaFiscalItemEstoque[],
      default: [] as [],
    },
    estoquesMovimento: {
      type: Array as () => IDTOProdutoEstoqueMovimento[],
      default: [] as [],
    },
    movimentarEntrada: {
      type: Boolean,
      default: false,
    },
    movimentarSaida: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    processando: {
      type: Boolean,
      default: false,
    },
    apresentarEstoques: {
      type: Boolean,
      default: false,
    },
    identificacaoElemento: {
      type: String,
      default: '',
    },
  },
  components: {
    CampoNumerico,
  },
  emits: ['update:quantidade', 'update:apresentarEstoques', 'update:estoquesMovimento', 'update:itensEstoque', 'update:processando', 'update:movimentarEstoque', 'focus'],
  setup(props, { emit }) {
    let debounceVerificarQuantidades = 0;

    const refSelecionarItemEstoque = ref<HTMLElement | null>(null);
    const servicoEstoque = new ServicoEstoque();
    const { apresentarMensagemAlerta } = useTelaBase();
    const state = reactive({
      buscandoDados: false,
      estoques: [] as IDTOProdutoEstoqueMovimento[],
      codigoProduto: 0,
    });

    const computedProcessando = computed({
      get: () => props.processando,
      set: (val: boolean) => {
        emit('update:processando', val);
      },
    });

    const computedApresentarEstoques = computed({
      get: () => props.apresentarEstoques,
      set: (val: boolean) => {
        emit('update:apresentarEstoques', val);
      },
    });

    const computedItensEstoque = computed({
      get: () => props.itensEstoque,
      set: (itens: INotaFiscalItemEstoque[]) => {
        emit('update:itensEstoque', itens);
      },
    });

    const computedEstoquesMovimento = computed({
      get: () => props.estoquesMovimento,
      set: (itens: IDTOProdutoEstoqueMovimento[]) => {
        emit('update:estoquesMovimento', itens);
      },
    });

    function limparCampos() {
      state.buscandoDados = false;
      state.estoques = [];
      computedItensEstoque.value = [];
    }

    function zerarQuantidadesUtilizadas() {
      for (let index = 0; index < computedItensEstoque.value.length; index += 1) {
        computedItensEstoque.value[index].quantidade = 0;
      }
    }

    function estrategiaVerificacaoEstoque() {
      console.log('Verificando estoque');
      if (UtilitarioGeral.validaLista(computedItensEstoque.value) && UtilitarioGeral.validaLista(computedEstoquesMovimento.value)) {
        if (props.quantidade > 0) {
          if (props.movimentarSaida) {
            let quantidadeRestante = 0;
            if (props.codigoUnidadePrincipal === props.codigoUnidadeSelecionada) {
              quantidadeRestante = props.quantidade;
            } else {
              quantidadeRestante = (props.quantidade * (props.fatorConversaoUnidadeSelecionada / props.fatorConversaoUnidadePrincipal));
            }
            for (let index = 0; index < computedItensEstoque.value.length; index += 1) {
              if (quantidadeRestante > 0) {
                const estoque = computedEstoquesMovimento.value.find((c) => c.codigoItemEstoque === computedItensEstoque.value[index].codigoEstoqueItem);
                if (estoque !== undefined) {
                  if (!estoque.estoqueNegativo && estoque.quantidadeDisponivel > 0 && estoque.quantidadeDisponivel > quantidadeRestante) {
                    computedItensEstoque.value[index].quantidade = quantidadeRestante;
                    quantidadeRestante = 0;
                  } else if (!estoque.estoqueNegativo && estoque.quantidadeDisponivel > 0 && estoque.quantidadeDisponivel < quantidadeRestante) {
                    computedItensEstoque.value[index].quantidade = estoque.quantidadeDisponivel;
                    quantidadeRestante -= estoque.quantidadeDisponivel;
                  } else if (estoque.estoqueNegativo) {
                    computedItensEstoque.value[index].quantidade = quantidadeRestante;
                    quantidadeRestante -= quantidadeRestante;
                  }
                }
              } else {
                computedItensEstoque.value[index].quantidade = 0;
              }
            }

            if (quantidadeRestante > 0) {
              apresentarMensagemAlerta('Produto sem estoque disponível!');
              zerarQuantidadesUtilizadas();
              emit('update:quantidade', 0);
            }
          } else if (props.movimentarEntrada) {
            let indexEstoquePreenchido = computedItensEstoque.value.findIndex((c) => c.quantidade > 0);
            if (indexEstoquePreenchido < 0) {
              indexEstoquePreenchido = 0;
            }
            computedItensEstoque.value[indexEstoquePreenchido].quantidade = props.quantidade;

            for (let index = 0; index < computedItensEstoque.value.length; index += 1) {
              if (indexEstoquePreenchido !== index) {
                computedItensEstoque.value[index].quantidade = 0;
              }
            }
          }
        } else {
          zerarQuantidadesUtilizadas();
        }
      }
    }

    async function obterEstoquesProduto() {
      state.buscandoDados = true;
      computedProcessando.value = true;
      state.codigoProduto = props.codigoProdutoDefinicao;
      const estoques = await servicoEstoque.obterEstoquesProdutoMovimento(props.codigoProdutoDefinicao, props.empresas, props.movimentarEntrada, props.movimentarSaida);
      computedEstoquesMovimento.value = estoques;
      state.estoques = estoques;
      if (UtilitarioGeral.validaLista(estoques)) {
        estoques.forEach((estoque) => {
          if (props.movimentarSaida) {
            if (!computedItensEstoque.value.some((c) => c.codigoEstoqueItem === estoque.codigoItemEstoque)) {
              const notaFiscalItemEstoque: INotaFiscalItemEstoque = {} as INotaFiscalItemEstoque;
              notaFiscalItemEstoque.codigo = 0;
              notaFiscalItemEstoque.codigoNotaFiscalItem = 0;
              notaFiscalItemEstoque.codigoEmpresa = estoque.codigoEmpresa;
              notaFiscalItemEstoque.codigoEstoque = estoque.codigoEstoque;
              notaFiscalItemEstoque.codigoEstoqueItem = estoque.codigoItemEstoque;
              notaFiscalItemEstoque.codigoCentroCusto = estoque.codigoCentroCusto;
              notaFiscalItemEstoque.quantidade = 0;
              computedItensEstoque.value.push(notaFiscalItemEstoque);
            }
          } else if (props.movimentarEntrada) {
            if (!computedItensEstoque.value.some((c) => c.codigoEstoque === estoque.codigoEstoque)) {
              const notaFiscalItemEstoque: INotaFiscalItemEstoque = {} as INotaFiscalItemEstoque;
              notaFiscalItemEstoque.codigo = 0;
              notaFiscalItemEstoque.codigoNotaFiscalItem = 0;
              notaFiscalItemEstoque.codigoEmpresa = estoque.codigoEmpresa;
              notaFiscalItemEstoque.codigoEstoque = estoque.codigoEstoque;
              notaFiscalItemEstoque.codigoEstoqueItem = estoque.codigoItemEstoque;
              notaFiscalItemEstoque.codigoCentroCusto = estoque.codigoCentroCusto;
              notaFiscalItemEstoque.quantidade = 0;
              computedItensEstoque.value.push(notaFiscalItemEstoque);
            }
          }
        });

        estrategiaVerificacaoEstoque();
      }

      state.buscandoDados = false;
      computedProcessando.value = false;
    }

    function obterDescricaoEstoque(codigoEstoque: number): string {
      let descricaoEstoque = '';
      const itemEstoque = state.estoques.find((c) => c.codigoEstoque === codigoEstoque);
      if (itemEstoque !== undefined) {
        if (UtilitarioGeral.valorValido(itemEstoque.sigla)) {
          descricaoEstoque = `${itemEstoque.sigla} - `;
        }
        descricaoEstoque += itemEstoque.descricao;
      }
      return descricaoEstoque;
    }

    function obterCasasDecimaisEstoque(codigoItemEstoque: number): number {
      let casasDecimais = 0;
      const itemEstoque = state.estoques.find((c) => c.codigoItemEstoque === codigoItemEstoque);
      if (itemEstoque !== undefined) {
        casasDecimais = itemEstoque.casasDecimaisUnidade;
      }
      return casasDecimais;
    }

    function obterQuantidadeDisponivel(codigoItemEstoque: number): number {
      let quantidadeDisponivel = 0;
      const itemEstoque = state.estoques.find((c) => c.codigoItemEstoque === codigoItemEstoque);
      if (itemEstoque !== undefined) {
        quantidadeDisponivel = itemEstoque.quantidadeDisponivel;
      }
      return quantidadeDisponivel;
    }

    watch(async () => props.codigoProdutoDefinicao, async () => {
      if (props.codigoProdutoDefinicao !== state.codigoProduto) {
        limparCampos();
      }
      if (props.codigoProdutoDefinicao > 0 && !UtilitarioGeral.validaLista(computedEstoquesMovimento.value)) {
        await obterEstoquesProduto();
      }
    }, { immediate: true });

    watch(async () => props.apresentarEstoques, async () => {
      if (props.apresentarEstoques && !state.buscandoDados && props.codigoProdutoDefinicao > 0 && !UtilitarioGeral.validaLista(computedEstoquesMovimento.value)) {
        await obterEstoquesProduto();
      }
    }, { immediate: true });

    function focus() {
      emit('focus');
    }

    function calcularQuantidades() {
      let totalQuantidade = 0;
      props.itensEstoque.forEach((itemEstoque) => {
        totalQuantidade += itemEstoque.quantidade;
      });

      if (totalQuantidade !== props.quantidade) {
        emit('update:quantidade', totalQuantidade);
      }
    }

    watch(() => props.quantidade, async () => {
      clearTimeout(debounceVerificarQuantidades);
      debounceVerificarQuantidades = setTimeout(() => {
        if (UtilitarioGeral.validaLista(computedEstoquesMovimento.value)) {
          estrategiaVerificacaoEstoque();
        }
      }, 600);
    }, { immediate: true });
    return {
      props,
      state,
      refSelecionarItemEstoque,
      computedItensEstoque,
      UtilitarioMascara,
      obterDescricaoEstoque,
      obterCasasDecimaisEstoque,
      obterQuantidadeDisponivel,
      focus,
      calcularQuantidades,
      computedApresentarEstoques,
    };
  },
});
