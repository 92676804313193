import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ss-tamanho-completo" }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_rastreabilidade = _resolveComponent("selecionar-tipo-rastreabilidade")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_tipo_lancamento_rastreabilidade = _resolveComponent("selecionar-tipo-lancamento-rastreabilidade")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_sim_nao = _resolveComponent("selecionar-sim-nao")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, { layout: "vertical" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_form_item, {
              label: "Tipo de Rastreabilidade",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_selecionar_tipo_rastreabilidade, {
                  valor: _ctx.computedTipoRastreabilidade,
                  "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedTipoRastreabilidade) = $event))
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form_item, {
              label: "Tipo de Lançamento de Rastreabilidade",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_selecionar_tipo_lancamento_rastreabilidade, {
                  valor: _ctx.computedTipoLancamentoRastreabilidade,
                  "onUpdate:valor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedTipoLancamentoRastreabilidade) = $event)),
                  disabled: (_ctx.computedTipoRastreabilidade === _ctx.ETipoRastreabilidade.Nenhum)
                }, null, 8, ["valor", "disabled"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_form_item, {
              label: "Ponto de Pedido",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico, {
                  valor: _ctx.computedPontoPedido,
                  "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedPontoPedido) = $event))
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_a_form_item, {
              label: "Tempo de Reposição(Dias)",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico, {
                  valor: _ctx.computedTempoReposicao,
                  "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedTempoReposicao) = $event)),
                  quantidadeCasasDecimais: 0
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_a_form_item, {
              label: "Bloquear novas compras(Sugestão/Pedidos)",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_selecionar_sim_nao, {
                  valor: _ctx.computedBloquearNovasCompras,
                  "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedBloquearNovasCompras) = $event)),
                  radioButton: true
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}