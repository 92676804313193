import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a56b4a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-col" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = {
  key: 0,
  class: "ant-col ant-col-xs-24 ant-col-xl-12"
}
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItensEstoque, (itemEstoque) => {
      return (_openBlock(), _createElementBlock("div", {
        key: itemEstoque.codigoEstoqueItem,
        onMouseenter: ($event: any) => (true),
        onMouseleave: ($event: any) => (false),
        class: "ant-row"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, { label: "Estoque" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.obterDescricaoEstoque(itemEstoque.codigoEstoque)), 1)
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        (_ctx.props.movimentarSaida)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_a_form_item, { label: "Quantidade Disponível:" }, {
                default: _withCtx(() => [
                  _createVNode(_component_campo_numerico, {
                    valor: _ctx.obterQuantidadeDisponivel(itemEstoque.codigoEstoqueItem),
                    disabled: true,
                    quantidadeCasasDecimais: _ctx.obterCasasDecimaisEstoque(itemEstoque.codigoEstoqueItem)
                  }, null, 8, ["valor", "quantidadeCasasDecimais"])
                ]),
                _: 2
              }, 1024)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.props.movimentarSaida? 'ant-col ant-col-xs-24 ant-col-xl-12':'ant-col ant-col-xs-24 ant-col-xl-12')
        }, [
          _createVNode(_component_a_form_item, { label: "Quantidade:" }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_numerico, {
                valor: itemEstoque.quantidade,
                "onUpdate:valor": ($event: any) => ((itemEstoque.quantidade) = $event),
                quantidadeCasasDecimais: _ctx.obterCasasDecimaisEstoque(itemEstoque.codigoEstoqueItem),
                class: _normalizeClass(_ctx.props.classCss),
                onValorDigitado: _cache[0] || (_cache[0] = ($event: any) => (_ctx.calcularQuantidades()))
              }, null, 8, ["valor", "onUpdate:valor", "quantidadeCasasDecimais", "class"])
            ]),
            _: 2
          }, 1024)
        ], 2)
      ], 32))
    }), 128)),
    _createElementVNode("div", {
      id: _ctx.props.identificacaoElemento
    }, null, 8, _hoisted_4)
  ]))
}