
import { computed, defineComponent, reactive } from 'vue';
import draggable from 'vuedraggable';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoCaracteristica, IProdutoCusto, IProdutoDefinicao } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { IDTOCaracteristicaVariacao, IDTOCaracteristicaVariacaoItem, IDTOCaracteristicaVariacaoSubItem } from '@/models/DTO/Cadastros/Produtos/IDTOCaracteristicaVariacao';
import { ICaracteristica } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Estoques/Rastreabilidade/ETipoRastreabilidade';
import { ETipoLancamentoRastreabilidade } from '@/models/Enumeradores/Estoques/Rastreabilidade/ETipoLancamentoRastreabilidade';
import SelecionarCaracteristica from './Caracteristicas/SelecionarCaracteristica.vue';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'ProdutoAdicionarVariacao',
  props: {
    definicoesProdutos: {
      type: Array as () => IProdutoDefinicao[],
      required: true,
    },
    caracteristicasCadastradas: {
      type: Array as () => ICaracteristica[],
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    MensagemSemDados,
    SelecionarCaracteristica,
    draggable,
  },
  emits: ['update:definicoesProdutos'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const computedDefinicoesProdutos = computed({
      get: () => props.definicoesProdutos,
      set: (valor: IProdutoDefinicao[]) => {
        emit('update:definicoesProdutos', valor);
      },
    });

    const state = reactive({
      apresentarModalVariacao: false,
      codigoCaracteristicaSelecionada: undefined,
      listaCaracteristicasCompleto: [] as IOption[],
      listaCaracteristicas: [] as IOption[],
      caracteristicasVariacao: [] as IDTOCaracteristicaVariacao[],
      custosPredefinidos: [] as IProdutoCusto [],
      precosPredefinidos: [] as ITabelaPrecoItemProduto [],
      preparandoCaracteristicas: false,
    });

    state.caracteristicasVariacao = [];
    state.custosPredefinidos = [];
    state.precosPredefinidos = [];

    function adicionarVariacao(custosPredefinidos:IProdutoCusto[], precosPredefinidos: ITabelaPrecoItemProduto[]) {
      state.apresentarModalVariacao = false;

      const listaCaracteristicas: IOption[] = [];
      if (UtilitarioGeral.validaLista(props.caracteristicasCadastradas)) {
        // Filtrar apenas ativas
        let caracteristicasFiltradas = props.caracteristicasCadastradas.filter((c) => c.ativo === true);
        // Filtrar apenas características selecionais
        caracteristicasFiltradas = props.caracteristicasCadastradas.filter((c) => c.tipo === ETipoCaracteristica.ListaSimples || c.tipo === ETipoCaracteristica.ListaSubItens || c.tipo === ETipoCaracteristica.Cor);
        caracteristicasFiltradas.forEach((caracteristica) => {
          const opcaoCaracteristica:IOption = {} as IOption;
          opcaoCaracteristica.value = caracteristica.codigo;
          opcaoCaracteristica.label = caracteristica.descricao;
          listaCaracteristicas.push(opcaoCaracteristica);
        });
      }

      state.custosPredefinidos = custosPredefinidos;
      state.precosPredefinidos = precosPredefinidos;
      state.listaCaracteristicasCompleto = listaCaracteristicas;
      state.listaCaracteristicas = listaCaracteristicas;
      state.caracteristicasVariacao = [];
      state.apresentarModalVariacao = true;
    }

    function defineApenasCaracteristicasNaoSelecionadas() {
      const listaCaracteristicas:IOption[] = UtilitarioGeral.instanciaObjetoLocal(state.listaCaracteristicasCompleto);
      if (UtilitarioGeral.validaLista(state.caracteristicasVariacao)) {
        state.caracteristicasVariacao.forEach((caracteristicaVariacao) => {
          const index = listaCaracteristicas.findIndex((c) => c.value === caracteristicaVariacao.codigo);
          if (index >= 0) {
            listaCaracteristicas.splice(index, 1);
          }
        });
      }
      state.listaCaracteristicas = listaCaracteristicas;
    }
    function adicionarCaracteristica() {
      if (!UtilitarioGeral.valorValido(state.codigoCaracteristicaSelecionada)) {
        apresentarMensagemAlerta('Selecione uma característica!');
        return;
      }

      if (UtilitarioGeral.validaLista(state.caracteristicasVariacao)) {
        if (state.caracteristicasVariacao.some((c) => c.codigo === state.codigoCaracteristicaSelecionada)) {
          apresentarMensagemAlerta('Característica já foi adicionada!');
        }
      }

      if (UtilitarioGeral.validaLista(props.caracteristicasCadastradas)) {
        const cadastroCaracteristica = props.caracteristicasCadastradas.find((c) => c.codigo === state.codigoCaracteristicaSelecionada);
        if (cadastroCaracteristica !== undefined) {
          const caracteristicaVariacao:IDTOCaracteristicaVariacao = {} as IDTOCaracteristicaVariacao;
          caracteristicaVariacao.codigo = cadastroCaracteristica.codigo;
          caracteristicaVariacao.descricao = cadastroCaracteristica.descricao;
          caracteristicaVariacao.valorPersonalizado = cadastroCaracteristica.descricao;
          caracteristicaVariacao.tipo = cadastroCaracteristica.tipo;
          caracteristicaVariacao.imprimirVendaNfe = false;
          caracteristicaVariacao.valorPersonalizado = '';
          caracteristicaVariacao.itens = [];
          if (caracteristicaVariacao.tipo === ETipoCaracteristica.ListaSimples || caracteristicaVariacao.tipo === ETipoCaracteristica.Cor) {
            cadastroCaracteristica.itens.filter((c) => c.ativo === true).forEach((itemCaracteristica) => {
              const itemCaracteristicaVariacao:IDTOCaracteristicaVariacaoItem = {} as IDTOCaracteristicaVariacaoItem;
              itemCaracteristicaVariacao.codigoItem = itemCaracteristica.codigo;
              itemCaracteristicaVariacao.valor = itemCaracteristica.valor;
              itemCaracteristicaVariacao.valorVisual = itemCaracteristica.valorVisual;
              itemCaracteristicaVariacao.selecionado = false;
              itemCaracteristicaVariacao.subItens = [];
              caracteristicaVariacao.itens.push(itemCaracteristicaVariacao);
            });
          } else if (caracteristicaVariacao.tipo === ETipoCaracteristica.ListaSubItens) {
            cadastroCaracteristica.itens.filter((c) => c.ativo === true).forEach((itemCaracteristica) => {
              const itemCaracteristicaVariacao:IDTOCaracteristicaVariacaoItem = {} as IDTOCaracteristicaVariacaoItem;
              itemCaracteristicaVariacao.codigoItem = itemCaracteristica.codigo;
              itemCaracteristicaVariacao.valor = itemCaracteristica.valor;
              itemCaracteristicaVariacao.valorVisual = itemCaracteristica.valorVisual;
              itemCaracteristicaVariacao.selecionado = false;
              itemCaracteristicaVariacao.subItens = [];
              itemCaracteristica.itens.forEach((subItemCaracteristica) => {
                const subItemCaracteristicaVariacao:IDTOCaracteristicaVariacaoSubItem = {} as IDTOCaracteristicaVariacaoSubItem;
                subItemCaracteristicaVariacao.codigoSubItem = subItemCaracteristica.codigo;
                subItemCaracteristicaVariacao.valor = subItemCaracteristica.valor;
                subItemCaracteristicaVariacao.selecionado = false;
                itemCaracteristicaVariacao.subItens.push(subItemCaracteristicaVariacao);
              });
              caracteristicaVariacao.itens.push(itemCaracteristicaVariacao);
            });
          } else if (caracteristicaVariacao.tipo === ETipoCaracteristica.Decimal || caracteristicaVariacao.tipo === ETipoCaracteristica.Numero) {
            caracteristicaVariacao.valorPersonalizado = '0';
          }

          state.caracteristicasVariacao.push(caracteristicaVariacao);
          defineApenasCaracteristicasNaoSelecionadas();
          state.codigoCaracteristicaSelecionada = undefined;
        } else {
          apresentarMensagemAlerta('Característica inválida ou cadastrado não localizado!');
        }
      }
    }

    function preparaCombinacoesComCodigosSelecionadosPorCaracteristica(arr:any) {
      const combinacoes = [];
      const repetir = true;
      let combinacao = '';

      const n = arr.length;

      const indices = new Array(n);

      for (let i = 0; i < n; i += 1) indices[i] = 0;

      while (repetir) {
        for (let i = 0; i < n; i += 1) combinacao += `${arr[i][indices[i]]},`;
        combinacoes.push(combinacao.substring(0, combinacao.length - 1));
        combinacao = '';

        let next = n - 1;
        while (next >= 0 && indices[next] + 1 >= arr[next].length) next -= 1;

        if (next < 0) return combinacoes;

        indices[next] += 1;

        for (let i = next + 1; i < n; i += 1) indices[i] = 0;
      }

      return [];
    }

    function preparaInformacoesSelecionadas() :string[] {
      const mensagensAlertaValidacao :string[] = [];
      const codigosSelecionados = new Array(state.caracteristicasVariacao.length);

      console.log('caracteristicas: ', state.caracteristicasVariacao);

      for (let i = 0; i < codigosSelecionados.length; (i += 1)) codigosSelecionados[i] = [];

      for (let i = 0; i < state.caracteristicasVariacao.length; (i += 1)) {
        if (state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.TextoLivre
            || state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.Decimal
            || state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.Numero) {
          if (UtilitarioGeral.valorValido(state.caracteristicasVariacao[i].valorPersonalizado)) {
            codigosSelecionados[i].push(state.caracteristicasVariacao[i].codigo);
          } else {
            mensagensAlertaValidacao.push(`Nenhum valor foi definido na característica: ${state.caracteristicasVariacao[i].descricao}`);
          }
        } else if (state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.ListaSimples
            || state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.Cor) {
          const itensSelecionados = state.caracteristicasVariacao[i].itens.filter((c) => c.selecionado === true);
          if (UtilitarioGeral.validaLista(itensSelecionados)) {
            for (let indexItem = 0; indexItem < itensSelecionados.length; (indexItem += 1)) {
              codigosSelecionados[i].push(itensSelecionados[indexItem].codigoItem);
            }
          } else {
            mensagensAlertaValidacao.push(`Nenhum item foi selecionado na característica: ${state.caracteristicasVariacao[i].descricao}`);
          }
        } else if (state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.ListaSubItens) {
          let subItemSelecionado = false;
          for (let indexItem = 0; indexItem < state.caracteristicasVariacao[i].itens.length; (indexItem += 1)) {
            const subItensSelecionados = state.caracteristicasVariacao[i].itens[indexItem].subItens.filter((c) => c.selecionado === true);
            for (let indexSubItem = 0; indexSubItem < subItensSelecionados.length; (indexSubItem += 1)) {
              codigosSelecionados[i].push(subItensSelecionados[indexSubItem].codigoSubItem);
              subItemSelecionado = true;
            }
          }

          if (!subItemSelecionado) {
            mensagensAlertaValidacao.push(`Nenhum item foi selecionado na característica: ${state.caracteristicasVariacao[i].descricao}`);
          }
        }
      }

      if (UtilitarioGeral.validaLista(mensagensAlertaValidacao)) {
        mensagensAlertaValidacao.forEach((mensagem) => {
          apresentarMensagemAlerta(mensagem);
        });
      } else {
        const combinacoes = preparaCombinacoesComCodigosSelecionadosPorCaracteristica(codigosSelecionados);
        return combinacoes;
      }

      return [];
    }
    function criaDefinicoesComVariacoes() {
      let definicoesProdutos:IProdutoDefinicao[] = [];

      if (!UtilitarioGeral.validaLista(state.caracteristicasVariacao)) {
        apresentarMensagemAlerta('Nenhuma característica selecionada ou definida!');
        return;
      }
      if (UtilitarioGeral.validaLista(props.definicoesProdutos)) {
        definicoesProdutos = UtilitarioGeral.instanciaObjetoLocal(props.definicoesProdutos);
      }

      const combinacoesASerCriadas = preparaInformacoesSelecionadas();
      if (UtilitarioGeral.validaLista(combinacoesASerCriadas)) {
        combinacoesASerCriadas.forEach((combinacao) => {
          const produtoDefinicao:IProdutoDefinicao = {} as IProdutoDefinicao;
          produtoDefinicao.codigo = 0;
          produtoDefinicao.codigoInterno = '';
          produtoDefinicao.bloquearNovasCompras = false;
          produtoDefinicao.tipoRastreabilidade = ETipoRastreabilidade.Nenhum;
          produtoDefinicao.tipoLancamentoRastreabilidade = ETipoLancamentoRastreabilidade.Manual;
          produtoDefinicao.custos = state.custosPredefinidos;
          produtoDefinicao.precos = state.precosPredefinidos;
          produtoDefinicao.codigos = [];
          produtoDefinicao.unidades = [];
          produtoDefinicao.imagens = [];
          produtoDefinicao.fornecedores = [];
          produtoDefinicao.caracteristicasVariacao = [];

          const codigosCombinacao = combinacao.split(',');
          if (UtilitarioGeral.validaLista(codigosCombinacao)) {
            for (let i = 0; i < codigosCombinacao.length; (i += 1)) {
              const caracteristicaVariacao:IProdutoCaracteristica = {} as IProdutoCaracteristica;
              caracteristicaVariacao.codigo = 0;
              caracteristicaVariacao.codigoProduto = 0;
              caracteristicaVariacao.codigoProdutoDefinicao = 0;
              caracteristicaVariacao.codigoCaracteristica = state.caracteristicasVariacao[i].codigo;
              caracteristicaVariacao.codigoCaracteristicaItem = 0;
              caracteristicaVariacao.tipo = state.caracteristicasVariacao[i].tipo;
              caracteristicaVariacao.variacao = true;
              caracteristicaVariacao.imprimirVendaNfe = state.caracteristicasVariacao[i].imprimirVendaNfe;
              caracteristicaVariacao.ordem = i;
              caracteristicaVariacao.caracteristicaPersonalizada = '';

              if (state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.TextoLivre
                  || state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.Decimal
                  || state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.Numero) {
                caracteristicaVariacao.caracteristicaPersonalizada = state.caracteristicasVariacao[i].valorPersonalizado;
              } else if (state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.ListaSimples
                     || state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.Cor) {
                caracteristicaVariacao.codigoCaracteristicaItem = Number(codigosCombinacao[i]);
              } else if (state.caracteristicasVariacao[i].tipo === ETipoCaracteristica.ListaSubItens) {
                let codigoItemCaracteristica = 0;
                state.caracteristicasVariacao[i].itens.forEach((itemCaracteristica) => {
                  const codigoSubItemEncontrado = itemCaracteristica.subItens.find((c) => c.codigoSubItem === Number(codigosCombinacao[i]));
                  if (codigoSubItemEncontrado !== undefined || codigoSubItemEncontrado !== null) {
                    codigoItemCaracteristica = itemCaracteristica.codigoItem;
                  }
                });

                caracteristicaVariacao.codigoCaracteristicaItem = codigoItemCaracteristica;
                caracteristicaVariacao.codigoCaracteristicaSubItem = Number(codigosCombinacao[i]);
              }
              produtoDefinicao.caracteristicasVariacao.push(caracteristicaVariacao);
            }
          }

          definicoesProdutos.push(produtoDefinicao);
        });

        computedDefinicoesProdutos.value = definicoesProdutos;
        state.apresentarModalVariacao = false;
      }
    }

    function removerCaracteristica(index:number) {
      state.caracteristicasVariacao.splice(index, 1);
      defineApenasCaracteristicasNaoSelecionadas();
    }

    return {
      props,
      computedDefinicoesProdutos,
      state,
      UtilitarioGeral,
      adicionarVariacao,
      adicionarCaracteristica,
      removerCaracteristica,
      criaDefinicoesComVariacoes,
      ETipoCaracteristica,
      storeSistema,
    };
  },
});
