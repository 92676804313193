import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ccbaee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ant-row"
}
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = {
  key: 1,
  class: "ant-row"
}
const _hoisted_4 = {
  class: "ant-col",
  style: {"flex":"1 1 auto"}
}
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_7 = { class: "ant-col ant-col-xs-20 ant-col-xl-4" }
const _hoisted_8 = {
  class: "ant-col ant-col-xs-4 ant-col-xl-1",
  style: {"text-align":"center"}
}
const _hoisted_9 = ["onClick", "disabled"]
const _hoisted_10 = {
  key: 3,
  class: "ant-row",
  style: {"padding-top":"5px"}
}
const _hoisted_11 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_12 = { class: "ss-margin-campos" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_15 = {
  key: 0,
  label: " ",
  class: "ss-margin-campos"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_selecionar_estoque_movimento_mobile = _resolveComponent("selecionar-estoque-movimento-mobile")!
  const _component_selecionar_empresa = _resolveComponent("selecionar-empresa")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_estoque = _resolveComponent("selecionar-estoque")!
  const _component_selecionar_centro_custo = _resolveComponent("selecionar-centro-custo")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            (_ctx.computedItem.estoques.length == 0 && !_ctx.storeSistema.state.layoutMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_mensagem_sem_dados, { mensagem: "Nenhum estoque adicionado." })
                  ])
                ]))
              : (_ctx.storeSistema.state.layoutMobile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_selecionar_estoque_movimento_mobile, {
                      empresas: [_ctx.props.empresa],
                      codigoProdutoDefinicao: _ctx.computedItem.codigoProdutoDefinicao,
                      "onUpdate:codigoProdutoDefinicao": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedItem.codigoProdutoDefinicao) = $event)),
                      movimentarSaida: true,
                      codigoUnidadePrincipal: _ctx.computedItem.codigoUnidadePrincipal,
                      fatorConversaoUnidadePrincipal: _ctx.computedItem.fatorUnidadePrincipal,
                      codigoUnidadeSelecionada: _ctx.computedItem.codigoUnidade,
                      fatorConversaoUnidadeSelecionada: _ctx.computedItem.fatorConversao,
                      quantidade: _ctx.computedItem.quantidade,
                      "onUpdate:quantidade": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedItem.quantidade) = $event)),
                      itensEstoque: _ctx.computedItem.estoques,
                      "onUpdate:itensEstoque": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedItem.estoques) = $event)),
                      apresentarEstoques: _ctx.state.apresentarEstoques
                    }, null, 8, ["empresas", "codigoProdutoDefinicao", "codigoUnidadePrincipal", "fatorConversaoUnidadePrincipal", "codigoUnidadeSelecionada", "fatorConversaoUnidadeSelecionada", "quantidade", "itensEstoque", "apresentarEstoques"])
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.computedItem.estoques, (estoque, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "ant-row",
                      key: index
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_form_item, {
                          label: "Empresa",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_selecionar_empresa, {
                              codigoSelecionado: estoque.codigoEmpresa,
                              "onUpdate:codigoSelecionado": ($event: any) => ((estoque.codigoEmpresa) = $event)
                            }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_a_form_item, {
                          label: "Estoque",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_selecionar_estoque, {
                              empresas: [_ctx.props.empresa],
                              "ignorar-estoques": _ctx.state.estoquesUtilizados,
                              estoques: _ctx.computedItem.codigosEstoquesDisponiveis,
                              pessoas: [_ctx.props.pessoa],
                              tiposEstoques: [1, 3],
                              codigoSelecionado: estoque.codigoEstoque,
                              "onUpdate:codigoSelecionado": ($event: any) => ((estoque.codigoEstoque) = $event),
                              onChange: _ctx.atualizarEstoquesUtilizados,
                              disabled: !_ctx.props.editavel || _ctx.UtilitarioGeral.validaLista(_ctx.computedItem.estoquesDisponiveis)
                            }, null, 8, ["empresas", "ignorar-estoques", "estoques", "pessoas", "codigoSelecionado", "onUpdate:codigoSelecionado", "onChange", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_a_form_item, {
                          label: "Centro de Custo",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_selecionar_centro_custo, {
                              empresas: [estoque.codigoEmpresa],
                              codigoSelecionado: estoque.codigoCentroCusto,
                              "onUpdate:codigoSelecionado": ($event: any) => ((estoque.codigoCentroCusto) = $event),
                              disabled: !_ctx.props.editavel
                            }, null, 8, ["empresas", "codigoSelecionado", "onUpdate:codigoSelecionado", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_a_form_item, {
                          label: "Quantidade",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_campo_numerico, {
                              quantidadeCasasDecimais: _ctx.computedItem.casasDecimaisUnidade,
                              fracionar: _ctx.computedItem.fracionarUnidade,
                              valor: estoque.quantidade,
                              "onUpdate:valor": [
                                ($event: any) => ((estoque.quantidade) = $event),
                                _cache[3] || (_cache[3] = ($event: any) => (_ctx.atualizarTotal()))
                              ],
                              disabled: (!_ctx.props.editavel && !_ctx.props.editarQuantidades) || _ctx.UtilitarioGeral.validaLista(_ctx.computedItem.estoquesDisponiveis)
                            }, null, 8, ["quantidadeCasasDecimais", "fracionar", "valor", "onUpdate:valor", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_a_form_item, { label: " " }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", {
                              class: "ant-btn ant-btn-secondary",
                              onClick: ($event: any) => (_ctx.removerEstoque(index)),
                              disabled: !_ctx.props.editavel
                            }, [
                              _createVNode(_component_icone, {
                                class: "ss-icone-remover",
                                nome: "excluir"
                              })
                            ], 8, _hoisted_9)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]))
                  }), 128)),
            (_ctx.UtilitarioGeral.validaLista(_ctx.computedItem.estoques))
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("button", {
                        class: "ant-btn ant-btn-secondary ss-acao-destaque-secundaria",
                        style: {"width":"100%"},
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.adicionarEstoque())),
                        disabled: !_ctx.props.editavel
                      }, "Adicionar Estoque", 8, _hoisted_13)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    (_ctx.UtilitarioGeral.validaLista(_ctx.computedItem.estoques))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("button", {
                            class: "ant-btn ant-btn-secondary",
                            style: {"width":"100%"},
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removerTodos()))
                          }, "Remover Todos")
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}